import { ChallengeType } from '@/common/enums'

export type ChallengeProps = {
  selectedValues: number[]
  closeWebview: () => void
  browserAndOs: string
  qrCode: string
  challengeType: `${ChallengeType}`
}

export type MatrixProps = Pick<ChallengeProps, 'selectedValues'> & {
  challengeType: ChallengeProps['challengeType']
}

export enum Feedbacks {
  EMPTY = 'EMPTY',
  CHALLENGE_UNAVAILABLE = 'CHALLENGE_UNAVAILABLE',
  CHALLENGE_ERROR = 'CHALLENGE_ERROR',
  TIME_EXPIRED = 'TIME_EXPIRED',
}

export type FeedbackProps = {
  onClose: () => void
  current: Feedbacks
}
