import styled, { css } from 'styled-components'

import MatrixOn from '@/assets/MatrixOn'
import MatrixOff from '@/assets/MatrixOff'

export const TitleContainer = styled.div`
  h1 {
    text-align: center;
    font-weight: bold !important;
  }
`

export const TitleBrowser = styled.div`
  p {
    text-align: center;
    margin-top: 16px;
  }
`

export const TitleFeedback = styled.div`
  p {
    text-align: center;
    margin-top: 16px;
    margin-bottom: 42px;
  }
`

export const MatrixContainer = styled.div<{
  size: number
}>`
  ${({ size }) => css`
    display: flex;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    min-height: ${size}px;
    max-height: ${size}px;
    min-width: ${size}px;
    max-width: ${size}px;
  `}
`

export const MatrixRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
`

export const MatrixItemChecked = styled(MatrixOn)`
  circle {
    cursor: pointer;
  }
`

export const MatrixItemUnchecked = styled(MatrixOff)``

export const BottomSheetContainer = styled.div`
  display: flex;
  flex-flow: column wrap;

  span + span {
    margin-top: 12px;
    margin-bottom: 32px;
  }

  button {
    margin-bottom: 0;
  }
`
