import { createMatrix } from '@/utils'

import * as S from './styles'
import * as Types from './types'

export const Matrix = ({ selectedValues, challengeType }: Types.MatrixProps) => {
  const MATRIX_CHALLENGE_TYPE = challengeType === 'NINE_DOTS' ? 3 : 4
  const SIZE = challengeType === 'NINE_DOTS' ? 272 : 312
  const MATRIX_VALUES = createMatrix(MATRIX_CHALLENGE_TYPE, MATRIX_CHALLENGE_TYPE)

  return (
    <S.MatrixContainer size={SIZE}>
      {MATRIX_VALUES.map((row) => (
        <S.MatrixRow>
          {row.map((value) =>
            selectedValues.includes(value) ? <S.MatrixItemChecked /> : <S.MatrixItemUnchecked />,
          )}
        </S.MatrixRow>
      ))}
    </S.MatrixContainer>
  )
}
