import { useMemo, useCallback } from 'react'

import ArrowLeft from '@interco/icons/orangeds/XL/arrow-left'
import { AppBar } from '@interco/inter-ui/components/AppBar'
import * as BridgeService from '@/services/bridge'

import * as Pages from './pages'
import * as S from './AppStyles'
import { ChallengeType } from './common/enums'

export default function Challenge() {
  const searchParams = useMemo(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const selectedValues = urlSearchParams.get('challengeSolution')
    const browserAndOs = urlSearchParams.get('browser') || ''
    const challengeType = urlSearchParams.get('challengeType') || ''
    const qrCode = window.location.search
      .split('&')
      .reduce(
        (_previousValue, currentValue) =>
          currentValue.startsWith('qrCode') ? currentValue.replace('qrCode=', '') : '',
        '',
      )

    return {
      selectedValues: selectedValues ? selectedValues.split(',').map(Number) : [],
      browserAndOs,
      challengeType,
      qrCode,
    }
  }, [])

  const closeWebview = useCallback(() => {
    BridgeService.requestGoBack()
  }, [])

  return (
    <S.Container>
      <AppBar
        leftIcon={
          <ArrowLeft height={24} width={24} onClick={closeWebview} color="var(--primary500)" />
        }
      />

      <Pages.Challenge
        selectedValues={searchParams.selectedValues}
        closeWebview={closeWebview}
        browserAndOs={searchParams.browserAndOs}
        qrCode={searchParams.qrCode}
        challengeType={searchParams.challengeType as ChallengeType}
      />
    </S.Container>
  )
}
