export enum QrCodeStatus {
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  LOGGED = 'LOGADO',
  CHALLENGE = 'CHALLENGE',
  CHALLENGE_UNAVAILABLE = 'CHALLENGE_UNAVAILABLE',
  CHALLENGE_ERROR = 'CHALLENGE_ERROR',
  CHALLENGE_TIME_EXPIRED = 'TIME_EXPIRED',
  CHALLENGE_FAILURE = 'CHALLENGE_FAILURE',
}

export enum ChallengeErrors {
  CHALLENGE_UNAVAILABLE = 'CHALLENGE_UNAVAILABLE',
  CHALLENGE_ERROR = 'CHALLENGE_ERROR',
  CHALLENGE_TIME_EXPIRED = 'TIME_EXPIRED',
  CHALLENGE_FAILURE = 'CHALLENGE_FAILURE',
}

export enum ChallengeType {
  MATRIX_NINE_DOTS = 'NINE_DOTS',
  MATRIX_SIXTEEN_DOTS = 'SIXTEEN_DOTS',
}
